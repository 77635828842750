@use '../../styles/colors';
@use '../../styles/layout';
@use '../../styles/mixins';
@use '../../styles/theme';

.desktopNavbarBrand {
  @media screen and (min-width: layout.$sm) {
    display: none;
  }
}

.desktopMenuNavbarBrand {
  margin-right: 2rem;
  @media screen and (max-width: layout.$sm) {
    display: none;
  }
}

.navbar {
  padding: 10px;
  width: 100%;

  .navbar-collapse {
    text-align: center;
    flex-grow: 0;
  }

  .navContainer {
    img {
      width: 30px;
      height: 30px;
      object-fit: contain;
    }

    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: layout.$maxWidth;
    margin: auto;
  }

  .navItem {
    align-self: center;
    margin-right: 10px;
  }

  :global(.dropdown-item) {
    background-color: transparent;

    &:hover,
    &:active {
      color: #1e2125;
      background-color: colors.$accent-3;
    }
  }

  .navDropdown {
    align-self: center;
    padding-right: 10px;

    &::after {
      position: relative;
      top: 2px;
    }
  }

  .navLink {
    vertical-align: middle;
    display: inline-block;
    text-align: center;
  }

  .ctaLinkContainer {
    display: flex;
    align-items: center;
  }

  .ctaLink {
    flex-grow: 0;

    button {
      @include mixins.primaryButton();
      background-color: colors.$brand-primary;
      min-height: 45px;
      padding: 0px 15px;
    }
  }

  .linksContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: layout.$md) {
      flex-direction: row;
      width: 100%;
      justify-content: flex-end;
    }

    > a {
      padding: 0 25px;
      color: colors.$black;
      font: normal normal bold 18px/18px 'SecondaryFontFamily';
      text-decoration: none;
      transition: opacity 100ms ease-in-out;

      &:hover {
        color: colors.$black;
        opacity: 0.75;
      }

      &:last-of-type {
        @include mixins.primaryButton();

        padding: 7px 14px;
        margin-left: 12px;
        font: normal normal bold 14px/18px 'SecondaryFontFamily';
      }
    }
  }
}

.mobile {
  display: none;
  @media screen and (max-width: layout.$md) {
    display: flex;
  }

  img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

.collapseContent {
  display: flex;
  flex-direction: column;
  padding-top: 16px;

  a {
    margin: 4px 0;
    padding: 7px 14px;
    color: colors.$black;
    text-align: center;
    text-decoration: none;

    &:hover {
      color: colors.$black;
      text-decoration: underline;
    }
  }
}

:global .navbar-light .navbar-brand {
  display: flex;
  align-items: center;
  height: 80px;

  img {
    height: 50px;
    width: auto;
    margin: auto;
    @media screen and (max-width: layout.$xs) {
      height: auto;
      width: 250px;
    }
  }
}

:global .nav-item:not(.show).active:after {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  width: 70%;
  margin-right: 10px;
  transform: translate(-50%);
  height: 5px;
  content: ' ';
  background-color: theme.$navbar-active-link-color;
}

:global .nav-link.active:after {
  position: absolute;
  left: 16px;
  right: 16px;
  height: 5px;
  content: ' ';
  background-color: theme.$navbar-active-link-color;
}

:global .bg-light {
  background-color: theme.$navbar-background-color !important;
  color: theme.$navbar-link-color !important;
}

:global .navbar-light .navbar-toggler {
  color: theme.$navbar-link-color;
  border-color: theme.$navbar-button-border-color;
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(250,247,242, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  }
}

:global .navbar-light .navbar-nav .nav-link {
  color: theme.$navbar-link-color;
  font-family: 'SecondarySemiBoldFontFamily';
}

:global .dropdown {
  text-align: center;
}

:global .dropdown-item.active {
  color: #1e2125;
  background-color: #c9c9c9a1;
}
