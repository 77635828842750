@use '../../styles/colors';
@use '../../styles/layout';

.ethos {
  .ethosContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;

    .copy {
      max-width: 675px;
      margin: auto;
      text-align: center;
      color: colors.$brand-primary;

      h1 {
        margin-top: 10px;
        margin-bottom: 10px;
        font: normal normal bold 44px/57px 'PrimaryFontFamily';
        color: colors.$brand-primary;
        margin-bottom: 30px;
      }

      p {
        font: normal normal 300 20px/32px 'SecondaryFontFamily';
      }
    }

    .cards {
      display: inline-flex;
      flex-wrap: wrap;
      vertical-align: top;
      max-width: 1640px;
      margin: auto;
      margin-bottom: 20px;

      @media (max-width: layout.$xs) {
        margin-bottom: 0;
      }

      .row {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0px;
        }

        @media (max-width: layout.$xs) {
          display: block;
        }

        .card {
          display: flex;
          flex-direction: column;
          flex-basis: 50%;
          flex-grow: 1;
          align-items: center;
          justify-content: center;
          max-width: 300px;
          padding: 10px;

          @media (max-width: layout.$lg) {
            flex: 1 1 50%;
          }

          @media (max-width: layout.$md) {
            flex: 1 1 50%;
          }

          @media (max-width: layout.$xs) {
            flex: 1 1 100%;
            max-width: layout.$xs;
          }

          img {
            flex: 1;
            max-height: 150px;
            object-fit: contain;
            margin-bottom: 10px;
          }

          .label {
            flex: 2;
            margin-top: 10px;
            font: normal normal 300 20px/32px 'SecondaryFontFamily';
            text-align: center;
            color: colors.$brand-primary;
          }
        }

        .circleCard {
          display: flex;
          flex-grow: 1;
          align-items: center;

          .outerCircle {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            margin: 5px;

            @media (max-width: layout.$xs) {
              margin: auto;
            }
          }

          .innerCircle {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: transparent;
            border-style: solid;
            text-align: center;
          }

          span {
            font: normal normal 300 20px/32px 'SecondarySemiBoldFontFamily';
            color: colors.$accent-5;
          }

          .label {
            display: flex;
            flex: 1;
            padding: 10px;
            align-items: center;
            justify-content: flex-start;

            p {
              font: normal normal 300 20px/32px 'SecondaryFontFamily';
              text-align: left;
              color: colors.$brand-primary;
              margin-bottom: 0;

              @media (max-width: layout.$xs) {
                text-align: center;
              }
            }
          }

          @media (max-width: layout.$xs) {
            display: block;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
